import React, { lazy, Suspense } from 'react';

const HotelCardsAroundEllipseMotionWithData = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'HotelCardsAroundEllipseMotionWithDataLazy' */ './HotelCardsAroundEllipseMotionWithData'));

const HotelCardsAroundEllipseMotionWithDataLazy = (props) => {
    return (
        <Suspense fallback={<div>Loading HotelCardsAroundEllipseMotionWithData ...</div>}>
            <HotelCardsAroundEllipseMotionWithData {...props} />
        </Suspense>
    )
}
export default HotelCardsAroundEllipseMotionWithDataLazy
